/* eslint-disable */
// prettier-ignore
import jwtDefaultConfig from '@/auth/jwt/jwtDefaultConfig'
import ability from '@/libs/acl/ability'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem(jwtDefaultConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return { name: 'access-control' }
  return { name: 'autenticacao' }
}

export const getUserInfo = () => {
  const data = getUserData()
  return data ? { nome: data.username, empresa: data.empresa, usuarioId: data.usuarioId } : {}
}

export const isGoingToLogging = (from, to) => {
  const fromDiffPaths = from.name !== to.name
  return fromDiffPaths && (to.path.startsWith('/login'))
}

/**
 * Limpa todos os dados de autorização
 */
export const clearLoggedIn = () => {
  localStorage.removeItem('userData')
  localStorage.removeItem(jwtDefaultConfig.storageTokenKeyName)
  localStorage.removeItem(jwtDefaultConfig.storagePermissoes)
}

export const isGoingToHome = (from, to) => {
  const fromDiffPaths = from.name !== to.name
  return fromDiffPaths && (to.path === '/')
}

export const isAdmin = () => {
  const userData = getUserData()
  return userData && userData.admin
}

export const isDemo = () => {
  const userData = getUserData()
  return userData && userData.baseDemo
}

export const updateAbility = ability => {
  const userData = getUserData()
  const existingAbility = userData ? userData.ability : null
  ability.update(existingAbility || [])
}
