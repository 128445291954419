export default [{
  path: '/parcelamento/das',
  component: {
    name: 'dasParcelamentoBase',
    template: '<router-view />',
    meta: {
      resource: 'das_parcelamento', //
      action: 'read',
      requiredAuth: true,
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [{
    path: 'visualizar',
    query: {
      busca: '',
      filtroRapido: '',
    },
    name: 'dasparcelamento-view',
    component: () => import('@/app/parcelamentos/ParcelamentoView.vue'),
    meta: {
      tipo: 'das',
      resource: 'das_parcelamento', //
      action: 'read',
      navActiveLink: 'dasparcelamento-view',
      pageTitle: 'Parcelamento Simples Nacional',
      breadcrumb: [
        {
          text: 'Parcelamento Simples Nacional',
          to: { name: 'dasparcelamento-view' },
          active: true,
        },
      ],
    },
  }],
},
{
  path: '/parcelamento/pertsn',
  component: {
    name: 'parcelamentoPertSNBase',
    template: '<router-view />',
    meta: {
      resource: 'parcelamento_pertsn', //
      action: 'read',
      requiredAuth: true,
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [{
    path: 'visualizar',
    name: 'parcelamento_pertsn-view',
    component: () => import('@/app/parcelamentos/ParcelamentoView.vue'),
    meta: {
      tipo: 'pert',
      resource: 'parcelamento_pertsn', //
      action: 'read',
      navActiveLink: 'parcelamento_pertsn-view',
      pageTitle: 'Parcelamento PERT-SN',
      breadcrumb: [
        {
          text: 'Parcelamento PERT-SN',
          to: { name: 'parcelamento_pertsn-view' },
          active: true,
        },
      ],
    },
  }],
},
{
  path: '/parcelamento/relpsn',
  component: {
    name: 'parcelamentoRelpSNBase',
    template: '<router-view />',
    meta: {
      resource: 'parcelamento_relpsn', //
      action: 'read',
      requiredAuth: true,
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [{
    path: 'visualizar',
    name: 'parcelamento_relpsn-view',
    component: () => import('@/app/parcelamentos/ParcelamentoView.vue'),
    meta: {
      tipo: 'relp',
      resource: 'parcelamento_relpsn', //
      action: 'read',
      navActiveLink: 'parcelamento_relpsn-view',
      pageTitle: 'Parcelamento RELP-SN',
      breadcrumb: [
        {
          text: 'Parcelamento RELP-SN',
          to: { name: 'parcelamento_relpsn-view' },
          active: true,
        },
      ],
    },
  }],
},
{
  path: '/parcelamento/rfb',
  component: {
    name: 'parcelamentoRfbBase',
    template: '<router-view />',
    meta: {
      resource: 'parcelamento_rfb',
      action: 'read',
      requiredAuth: true,
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [{
    path: 'visualizar',
    name: 'parcelamento_rfb-view',
    component: () => import('@/app/parcelamentos/ParcelamentoView.vue'),
    meta: {
      tipo: 'rfb',
      resource: 'parcelamento_rfb',
      action: 'read',
      navActiveLink: 'parcelamento_rfb-view',
      pageTitle: 'Parcelamento RFB',
      breadcrumb: [
        {
          text: 'Parcelamento RFB',
          to: { name: 'parcelamento_rfb-view' },
          active: true,
        },
      ],
    },
  }],
},
]
