export default [{
  path: '/parcelamento/pgfn',
  component: {
    name: 'parcelamentoPgfnBase',
    template: '<router-view />',
    meta: {
      resource: 'pgfn_parcelamento',
      action: 'read',
      requiredAuth: true,
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [{
    path: 'visualizar',
    name: 'pgfnparcelamento-view',
    component: () => import('@/app/parcelamentos/parcelamento-pgfn/ParcelamentoPgfnView.vue'),
    meta: {
      tipo: 'pgfn',
      resource: 'pgfn_parcelamento',
      action: 'read',
      navActiveLink: 'pgfnparcelamento-view',
      pageTitle: 'Parcelamento PGFN',
      breadcrumb: [
        {
          text: 'Parcelamentos',
          to: { name: 'pgfnparcelamento-view' },
          active: true,
        },
      ],
    },
  },
  {
    path: ':id/emissao',
    name: 'emissao_pgfn_Parcelamento-view',
    component: () => import('@/app/parcelamentos/parcelamento-pgfn/shared/component/ParcelamentoPgfnEmissao.vue'),
    meta: {
      tipo: 'pgfn',
      resource: 'pgfn_parcelamento',
      action: 'read',
      navActiveLink: 'emissao_pgfn_Parcelamento-view',
      pageTitle: 'Parcelamento PGFN',
      breadcrumb: [
        {
          text: 'Parcelamentos',
          to: { name: 'pgfnparcelamento-view' },
        },
        {
          text: 'Emissão de Documentos',
          to: { name: 'emissao_pgfn_Parcelamento-view' },
          active: true,
        },
      ],
    },
  }],
},
]
