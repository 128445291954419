export default [{
  name: 'redefinicao-senha',
  path: '/acesso/redefinicao-senha/',
  component: () => import('@/app/acesso/redefinicao-senha/RedefinicaoSenha.vue'),
  meta: {
    resource: 'monitorcontabil',
    action: '',
    requiredAuth: false,
    layout: 'full',
  },
}]
