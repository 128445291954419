import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import VueApexCharts from 'vue-apexcharts'
import BootstrapVue from 'bootstrap-vue'
import Notificacao from '@pilar/plugins/notificacao'
import VueDebounce from 'vue-debounce'
import Confirmacao from '@pilar/plugins/confirmacao'
import VueProgressBar from 'vue-progressbar'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import '@/libs/sweet-alerts'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// You can add your icons directly in this plugin. See other examples for how you
// can add other styles or just individual icons.
library.add(far, fas, fab)
// Register the component globally
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueApexCharts)
Vue.use(BootstrapVue)
Vue.use(Notificacao)
Vue.use(Confirmacao)
Vue.use(VueDebounce)

Vue.use(VueProgressBar, {
  color: '#007f8e',
  height: '4px',
  speed: '0.6s',
})
Vue.component('apexchart', VueApexCharts)
