export default [{
  path: '/caixa-postal',
  component: {
    name: 'caixapostalBase',
    template: '<router-view />',
    meta: {
      resource: 'caixapostal_ecac',
      action: 'read',
      requiredAuth: true,
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [{
    path: 'visualizar',
    name: 'caixapostal-view',
    component: () => import('@/app/caixa-postal/CaixaPostalView.vue'),
    meta: {
      resource: 'caixapostal_ecac',
      action: 'read',
      navActiveLink: 'caixapostal-view',
      pageTitle: 'Caixa postal e-CAC',
      breadcrumb: [
        {
          text: 'Caixa postal e-CAC',
          to: { name: 'caixapostal-view' },
          active: true,
        },
      ],
    },
  },
  {
    path: ':id/mensagens',
    query: {
      nome: '',
    },
    name: 'mensagem-view',
    component: () => import('@/app/caixa-postal/shared/components/mensagem-view/MensagemView.vue'),
    meta: {
      resource: 'caixapostal_ecac',
      action: 'read',
      navActiveLink: 'caixapostal-view',
      pageTitle: 'Mensagens caixa postal e-CAC',
      breadcrumb: [
        {
          text: 'Caixa postal e-CAC',
          to: { name: 'caixapostal-view' },
        },
        {
          text: 'Mensagens',
          to: { name: 'mensagem-view' },
          active: true,
        },
      ],
    },
  }],
},
]
