export default [{
  path: '/cnd-estadual',
  component: {
    name: 'cndestadualBase',
    template: '<router-view />',
    meta: {
      resource: 'cnd_estadual', //
      action: 'read',
      requiredAuth: true,
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [{
    path: 'visualizar',
    // query: {
    //   busca: '',
    //   filtroRapido: '',
    // },
    name: 'cndestadual-view',
    component: () => import('@/app/cnd-estadual/CndEstadualView.vue'),
    meta: {
      resource: 'cnd_estadual', //
      action: 'read',
      navActiveLink: 'cndestadual-view',
      pageTitle: 'Estadual',
      breadcrumb: [
        {
          text: 'Estadual',
          to: { name: 'cndestadual-view' },
          active: true,
        },
      ],
    },
  }],
},
]
