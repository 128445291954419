import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import appModulos from '@/app/router'
import {
  isUserLoggedIn, isGoingToLogging, isAdmin,
} from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: appModulos,
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  if (!isGoingToLogging(from, to)) {
    if (to.meta.requiredAuth === false) {
      next()
      return
    }
    if (isUserLoggedIn()) {
      if (isAdmin() || (!isAdmin() && canNavigate(to))) {
        next()
        return
      }

      // Se não tem acesso a rota tem que ver para qual login ele será redirecionado
      if (!canNavigate(to)) {
        if (window.location.href.indexOf(to.path) !== -1) {
          next({
            path: '',
            query: { redirect: to.fullPath },
          })
        }
        Vue.swal({
          title: 'Atenção',
          text: `Você não tem permissão para acessar o menu ${to.meta.pageTitle}`,
          icon: 'warning',
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-outline-primary mr-50',
          },
          buttonsStyling: false,
          // Força a seleção da modal para poder fechar ela com o ESC
          onOpen: () => {
            document.querySelector('.swal2-popup').focus()
          },
        })
        return
      }
    }

    next({
      path: '/login',
      query: { redirect: to.fullPath },
    })
    return
  }
  setTimeout(() => next(), 5)
})

export default router
