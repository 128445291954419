export default [{
  path: '/empresas',
  component: {
    name: 'empresaBase',
    template: '<router-view />',
    meta: {
      resource: 'Empresa',
      action: 'read',
      requiredAuth: true,
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [{
    path: 'visualizar',
    name: 'empresa-view',
    component: () => import('@/app/empresas/EmpresaView.vue'),
    meta: {
      resource: 'empresa',
      action: 'read',
      requiredAuth: true,
      navActiveLink: 'empresas',
      pageTitle: 'Empresas',
      breadcrumb: [
        {
          text: 'Empresas',
          to: { name: 'empresa-view' },
          active: true,
        },
      ],
    },
  },
  {
    path: 'novo',
    name: 'empresa-novo',
    component: () => import('@/app/empresas/shared/components/cadastro/EmpresaCadastro.vue'),
    meta: {
      resource: 'empresa',
      action: 'create',
      requiredAuth: true,
      navActiveLink: 'empresa-view',
      pageTitle: 'Cadastro de empresas',
      breadcrumb: [
        {
          text: 'Empresas',
          to: { name: 'empresa-view' },
        },
        {
          text: 'Novo',
          to: { name: 'empresa-novo' },
          active: true,
        },
      ],
    },
  }, {
    path: 'editar/:id',
    name: 'empresa-editar',
    component: () => import('@/app/empresas/shared/components/cadastro/EmpresaCadastro.vue'),
    meta: {
      resource: 'empresa',
      action: 'update',
      requiredAuth: true,
      navActiveLink: 'empresa-view',
      pageTitle: 'Cadastro de empresa',
      breadcrumb: [
        {
          text: 'Empresas',
          to: { name: 'empresa-view' },
        },
        {
          text: 'Editar',
          to: { name: 'empresa-editar' },
          active: true,
        },
      ],
    },
  },
  ],
},
]
