/* eslint-disable no-param-reassign */
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default
{

  getIcone: tipo => {
    if (tipo === 'success') {
      return 'fa-solid fa-circle-check'
    } if (tipo === 'alert') {
      return 'fa-solid fa-triangle-exclamation'
    } if (tipo === 'danger') {
      return 'fa-solid fa-circle-exclamation'
    }
    return ''
  },

  install(Vue) {
    Vue.prototype.$erro = titulo => {
      Vue.prototype.$toast({
        component: ToastificationContent,
        props: {
          title: titulo,
          icon: this.getIcone('danger'),
          variant: 'danger',
        },
      })
    }

    Vue.prototype.$aviso = titulo => {
      Vue.prototype.$toast({
        component: ToastificationContent,
        props: {
          title: titulo,
          icon: this.getIcone('alert'),
          variant: 'warning',
        },
      })
    }

    Vue.prototype.$erroResponse = response => {
      const { data } = response
      let titulo = ''
      if (data.errors) {
        // eslint-disable-next-line prefer-destructuring
        titulo = data.errors[0]
      } else {
        titulo = data.message
      }
      Vue.prototype.$toast({
        component: ToastificationContent,
        props: {
          title: titulo,
          icon: this.getIcone('danger'),
          variant: 'danger',
        },
      })
    }

    Vue.prototype.$sucesso = titulo => {
      Vue.prototype.$toast({
        component: ToastificationContent,
        props: {
          title: titulo,
          icon: this.getIcone('success'),
          variant: 'success',
        },
      })
    }
  },
}
