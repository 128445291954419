export default [{
  path: '/certificado',
  component: {
    name: 'certificadoBase',
    template: '<router-view />',
    meta: {
      resource: 'certificadodigital',
      action: 'read',
      requiredAuth: true,
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [{
    path: 'visualizar',
    name: 'certificado-view',
    component: () => import('@/app/certificado-digital/CertificadoView.vue'),
    meta: {
      resource: 'certificadodigital',
      action: 'read',
      navActiveLink: 'certificado-view',
      pageTitle: 'Certificados Digitais',
      breadcrumb: [
        {
          text: 'Certificados',
          to: { name: 'certificado-view' },
          active: true,
        },
      ],
    },
  },
  {
    path: 'novo',
    name: 'certificado-novo',
    component: () => import('@/app/certificado-digital/shared/components/cadastro/CertificadoDigitalCadastro.vue'),
    meta: {
      resource: 'certificadodigital',
      action: 'create',
      navActiveLink: 'certificado-novo',
      pageTitle: 'Novo certificado digital',
      breadcrumb: [
        {
          text: 'Certificados Digitais',
          to: { name: 'certificado-view' },
        },
        {
          text: 'Novo',
          to: { name: 'certificado-novo' },
          active: true,
        },
      ],
    },
  },
  {
    path: 'editar/:id',
    name: 'certificado-editar',
    component: () => import('@/app/certificado-digital/shared/components/cadastro/CertificadoDigitalCadastro.vue'),
    meta: {
      resource: 'certificadodigital',
      action: 'update',
      navActiveLink: 'certificado-editar',
      pageTitle: 'Editar certificado digital',
      breadcrumb: [
        {
          text: 'Certificados Digitais',
          to: { name: 'certificado-view' },
        },
        {
          text: 'Editar',
          to: { name: 'certificado-editar' },
          active: true,
        },
      ],
    },
  }],
}]
