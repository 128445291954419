export default [{
  path: '/cnd-federal',
  component: {
    name: 'cndfederalBase',
    template: '<router-view />',
    meta: {
      resource: 'cnd_federal',
      action: 'read',
      requiredAuth: true,
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [{
    path: 'visualizar',
    query: {
      busca: '',
      filtroRapido: '',
    },
    name: 'cndfederal-view',
    component: () => import('@/app/cnd-federal/CndFederalView.vue'),
    meta: {
      resource: 'cnd_federal',
      action: 'read',
      navActiveLink: 'cndfederal-view',
      pageTitle: 'RFB/PGFN',
      breadcrumb: [
        {
          text: 'RFB/PGFN',
          to: { name: 'cndfederal-view' },
          active: true,
        },
      ],
    },
  }],
},
]
