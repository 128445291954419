export default [
  {
    name: 'novo-tenant',
    path: '/acesso/novo-tenant',
    component: () => import('@app/acesso/novo-tenant/NovoTenant.vue'),
    meta: {
      resource: 'login',
      action: '',
      requiredAuth: false,
      layout: 'full',
    },
  },
]
