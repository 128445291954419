export default [{
  path: '/perfil-empresa',
  name: 'perfil-empresa',
  component: () => import('@/app/perfil-empresa/PerfilEmpresa.vue'),
  meta: {
    resource: 'perfil_empresa',
    action: 'create',
    pageTitle: 'Perfil empresa',
    breadcrumb: [],
  },
}]
