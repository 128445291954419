export default [{
  path: '/cnd-portal-documento',
  component: {
    name: 'cndportaldocumentoBase',
    template: '<router-view />',
    meta: {
      resource: 'cnd_portal_documentos',
      action: 'read',
      requiredAuth: true,
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [{
    path: 'visualizar',
    query: {
      busca: '',
      filtroRapido: '',
    },
    name: 'cnd-portal-documento-view',
    component: () => import('@/app/cnd-portal-documento/CndPortalDocumentosView.vue'),
    meta: {
      resource: 'cnd_portal_documentos',
      action: 'read',
      navActiveLink: 'cnd-portal-documento-view',
      pageTitle: 'Portal de CNDs',
      breadcrumb: [
        {
          text: 'Portal de  CNDs',
          to: { name: 'cnd-portal-documento-view' },
          active: true,
        },
      ],
    },
  }],
},
]
