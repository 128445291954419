import Vue from 'vue'
import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr'
import { $backEndUrl } from '@serverConfig'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import EventBus from '../mixins/event-bus/eventBus'

let connection = {}

// cria a conexão padrão com o backend
function createConnection() {
  return new HubConnectionBuilder()
    .configureLogging(LogLevel.Critical)
  // .configureLogging(LogLevel.Debug)
    .withUrl(`${$backEndUrl}/hubs/events`, {
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets,
    })
    .build()
}

// inicia a conexão e retorna uma promise
function startConnection(connec) {
  return connec.start()
}

// define todos os eventos esperados do backend (caso sejam criados novos eventos lá, necessário incluir aqui para recebê-los)
function setEventHandlers(connec) {
  // const toast = useToast()

  // chamado logo após ser conectado ao hub
  connec.on('conectado', () => {
  })

  connec.on('notificacao', (mensagem, tipoNotificacao) => {
    let toastProps = {
      title: mensagem,
    }
    switch (tipoNotificacao) {
      case 'sucesso':
        toastProps = { ...toastProps, icon: 'fa-solid fa-circle-check', variant: 'success' }
        break
      case 'erro':
        toastProps = { ...toastProps, icon: 'fa-solid fa-circle-exclamation', variant: 'danger' }
        break
      case 'concluidocomerros':
        toastProps = { ...toastProps, icon: 'fa-solid fa-circle-check', variant: 'success' }
        break
      case 'aviso':
        toastProps = { ...toastProps, icon: 'fa-solid fa-triangle-exclamation', variant: 'warning' }
        break
      case 'iniciado':
        toastProps = { ...toastProps, icon: 'fa-solid fa-hourglass-end', variant: 'success' }
        break
      default:
        throw new Error('invalid notification type')
    }
    Vue.$toast({
      component: ToastificationContent,
      props: toastProps,
    })
  })

  // atualiza a lista de baixa em lote
  connec.on('atualizaBaixa', () => {
    EventBus.$emit('atualizaBaixa')
  })

  connec.on('atualizaObservacoes', () => {
    EventBus.$emit('atualizaObservacoes')
  })

  connec.on('atualizaCertificadosVencidos', () => {
    EventBus.$emit('atualizaCertificadosVencidos')
  })

  connec.on('atualizarPlano', novoPlano => {
    EventBus.$emit('atualizarPlano', novoPlano)
  })
}

// chamado quando acontece um erro ou quando o hub é desconectado
function rebootConnection() {
  connection = createConnection()
  startConnection(connection)
    .then(() => {
      connection.invoke('VinculateUser', localStorage.accessToken)
      setEventHandlers(connection)
      connection.onclose(() => rebootConnection())
    }).catch(() => new Promise((resolve, reject) => setTimeout(() => rebootConnection().then(resolve).catch(reject), 5000)))
}

// função de conexão com a hub
function start() {
  connection = createConnection()
  startConnection(connection).then(() => {
    connection.invoke('VinculateUser', localStorage.accessToken)
    setEventHandlers(connection)
    connection.onclose(() => rebootConnection())
  }).catch(() => new Promise((resolve, reject) => setTimeout(() => rebootConnection().then(resolve).catch(reject), 5000)))
}

export default function (startHub = true) {
  if (startHub) start()
  return connection
}
