export default {
  // Endpoints
  loginEndpoint: '/acessos/autenticar',
  alterarSenhaEndpoint: '/acessos/trocar-senha',
  forgotPasswordEndpoint: '/acessos/esqueci-senha',
  registerEndpoint: '',
  refreshEndpoint: '',
  logoutEndpoint: '',

  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storagePermissoes: 'permissoes',
  storageRefreshTokenKeyName: '',
}
