export default [{
  path: '/parcelamento/nao-previdenciario',
  component: {
    name: 'parcelamentoNaoPrevidenciarioBase',
    template: '<router-view />',
    meta: {
      resource: 'parcelamento_naoprevidenciario',
      action: 'read',
      requiredAuth: true,
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [{
    path: 'visualizar',
    name: 'parcelamento_nao_previdenciario-view',
    component: () => import('@/app/parcelamentos/parcelamento-nao-previdenciario/ParcelamentoNaoPrevidenciario.vue'),
    meta: {
      tipo: 'nao-previdenciario',
      resource: 'parcelamento_naoprevidenciario',
      action: 'read',
      navActiveLink: 'parcelamento_nao_previdenciario-view',
      pageTitle: 'Não Previdenciário',
      breadcrumb: [
        {
          text: 'Parcelamento Não Previdenciário',
          to: { name: 'parcelamento_pertsn-view' },
          active: true,
        },
      ],
    },
  }],
},
]
