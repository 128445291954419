export default [{
  path: '/declaracao-dctf-web',
  component: {
    name: 'declaracaodctfwebBase',
    template: '<router-view />',
    meta: {
      resource: 'declaracao_dctfweb',
      action: 'read',
      requiredAuth: true,
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [{
    path: 'visualizar',
    query: {
      busca: '',
      filtroRapido: '',
    },
    name: 'dctfweb-view',
    component: () => import('@/app/declaracao-dctf-web/DeclaracaoDctfWebView.vue'),
    meta: {
      resource: 'declaracao_dctfweb',
      action: 'read',
      navActiveLink: 'dctfweb-view',
      pageTitle: 'DCTFWeb',
      breadcrumb: [
        {
          text: 'DCTFWeb',
          to: { name: 'dctfweb-view' },
          active: true,
        },
      ],
    },
  }],
},
]
