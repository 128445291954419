export default [{
  path: '/email/configucaracao',
  name: 'email-config',
  component: () => import('@/app/email-config/EmailConfig.vue'),
  meta: {
    resource: 'emailconfig',
    action: 'create',
    pageTitle: 'Configuração de SMTP',
    breadcrumb: [],
  },
}]
