export default [{
  path: '/cnd-fgts',
  component: {
    name: 'cndftgsBase',
    template: '<router-view />',
    meta: {
      resource: 'cnd_fgts',
      action: 'read',
      requiredAuth: true,
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [{
    path: 'visualizar',
    query: {
      busca: '',
      filtroRapido: '',
    },
    name: 'cndfgts-view',
    component: () => import('@/app/cnd-fgts/CndFgtsView.vue'),
    meta: {
      resource: 'cnd_fgts',
      action: 'read',
      navActiveLink: 'cndfgts-view',
      pageTitle: 'FGTS',
      breadcrumb: [
        {
          text: 'FGTS',
          to: { name: 'cndfgts-view' },
          active: true,
        },
      ],
    },
  }],
},
]
