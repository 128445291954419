export default [{
  path: '/cnd-trabalhista',
  component: {
    name: 'cndtrabalhistaBase',
    template: '<router-view />',
    meta: {
      resource: 'cnd_trabalhista',
      action: 'read',
      requiredAuth: true,
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [{
    path: 'visualizar',
    query: {
      busca: '',
      filtroRapido: '',
    },
    name: 'cndtrabalhista-view',
    component: () => import('@/app/cnd-trabalhista/CndTrabalhistaView.vue'),
    meta: {
      resource: 'cnd_trabalhista',
      action: 'read',
      navActiveLink: 'cndtrabalhista-view',
      pageTitle: 'Trabalhista',
      breadcrumb: [
        {
          text: 'Trabalhista',
          to: { name: 'cndtrabalhista-view' },
          active: true,
        },
      ],
    },
  }],
},
]
