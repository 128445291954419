export default [{
  path: '/parametros-sistema',
  name: 'parametros-sistema',
  component: () => import('@/app/parametros-sistema/ParametroSistemaView.vue'),
  meta: {
    resource: 'parametro_sistema',
    action: 'create',
    pageTitle: 'Parâmetros do Sistema',
    breadcrumb: [],
  },
}]
