export default [
  {
    name: 'auth-login',
    path: '/login',
    component: () => import('@app/acesso/login/Login.vue'),
    meta: {
      resource: 'login',
      action: '',
      requiredAuth: false,
      layout: 'full',
    },
  },
]
