export default [{
  path: '/plano',
  component: {
    name: 'planoView',
    template: '<router-view />',
    meta: {
      resource: 'plano',
      action: 'create',
      requiredAuth: true,
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [{
    path: 'visualizar',
    name: 'plano-view',
    component: () => import('@/app/plano/PlanoView.vue'),
    meta: {
      resource: 'plano', //
      action: 'create',
      navActiveLink: 'plano-view',
      pageTitle: 'Dados do Plano',
      breadcrumb: [
        {
          text: 'Plano',
          to: { name: 'plano-view' },
          active: true,
        },
      ],
    },
  }],
},
]
