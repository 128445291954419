export default [{
  name: 'dashboard-analitico',
  path: '/',
  component: () => import('@app/analitico/Analitico.vue'),
  meta: {
    resource: '',
    action: 'monitorcontabil',
    requiredAuth: true,
    pageTitle: 'Dashboard analítico',
    breadcrumb: [
      { text: 'Dashboard analítico', to: { path: '/' } },
    ],
  },
}]
