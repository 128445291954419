export default [{
  name: 'confirmar-acessp',
  path: '/acesso/confirmar',
  component: () => import('../../ConfirmarAcesso.vue'),
  meta: {
    resource: 'confirmarAcesso',
    action: '',
    requiredAuth: false,
    layout: 'full',
  },
}]
