export default [{
  path: '/situacao-fiscal',
  component: {
    name: 'situacaoFiscal',
    template: '<router-view />',
    meta: {
      resource: 'situacaofiscal_ecac',
      action: 'read',
      requiredAuth: true,
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [{
    path: 'visualizar',
    name: 'situacaofiscal-view',
    component: () => import('@/app/situacao-fiscal/SituacaoFiscalView.vue'),
    meta: {
      resource: 'situacaofiscal_federal',
      action: 'read',
      requiredAuth: true,
      navActiveLink: 'situacaofiscal-view',
      pageTitle: 'Situação fiscal',
      breadcrumb: [
        {
          text: 'Situação fiscal',
          to: { name: 'situacaofiscal-view' },
          active: true,
        },
      ],
    },
  }],
},
]
