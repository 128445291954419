import novoTenant from '../novo-tenant/shared/router'
import confirmarAcesso from '../confirmar-acesso/shared/router'
import login from '../login/shared/router'
import redefinicaoSenha from '../redefinicao-senha/shared/router'

export default [
  ...login,
  ...novoTenant,
  ...confirmarAcesso,
  ...redefinicaoSenha,
]
